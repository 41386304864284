*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}

:root {
    /* fonts */
    --lato-font: Lato;
    --font-dm-sans: "DM Sans";

    /* font sizes */
    --paragraph-01-bold-size: 1.5rem;
    --font-size-lgi: 1.188rem;
    --subheading-02-bold-size: 1rem;
    --paragraph-03-bold-size: 1.125rem;
    --paragraph-02-bold-size: 1.25rem;
    --display-02-extrabold-size: 3rem;
    --font-size-10xl: 1.813rem;
    --font-size-19xl: 2.375rem;
    --caption-02-regular-size: 0.625rem;
    --caption-01-bold-size: 0.75rem;
    --heading-01-bold-size: 2rem;
    --font-size-7xl: 1.625rem;
    --font-size-base-6: 1.038rem;
    --font-size-base-9: 0.994rem;

    /* Colors */
    --color-white: #fff;
    --secondary: #831111;
    --white: #fbfafa;
    --color-snow-100: rgba(251, 250, 250, 0.93);
    --primary: #db1d1d;
    --gray-2: #bdbdbd;
    --neutral-50: #fdfdfd;
    --black: #282828;
    --color-whitesmoke-100: #f1efef;
    --color-black: #000;
    --warning: #fabc00;
    --primary-dark: #bf161d;
    --color-gainsboro: rgba(224, 224, 224, 0.93);
    --color-brown: #a83838;
    --color-info: #2f80ed;
    --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-100: hsl(var(--gray-hue), 47%, 94%);

    /* Gaps */
    --gap-xl: 1.25rem;
    --gap-16xl: 2.188rem;
    --gap-5xs: 0.5rem;
    --gap-base: 1rem;
    --gap-13xl: 2rem;
    --gap-23xl: 2.625rem;
    --gap-2xl: 1.313rem;
    --gap-5xl: 1.5rem;
    --gap-10xs: 0.188rem;
    --gap-9xs: 0.25rem;
    --gap-11xs: 0.125rem;
    --gap-139xl: 9.875rem;
    --gap-60xl: 4.938rem;
    --gap-20xl: 2.438rem;
    --gap-mini: 0.938rem;
    --gap-4xs: 0.563rem;

    /* Paddings */
    --padding-63xl: 5.125rem;
    --padding-22xl: 2.563rem;
    --padding-9xs: 0.25rem;
    --padding-xl: 1.25rem;
    --padding-10xl: 1.813rem;
    --padding-sm: 0.875rem;
    --padding-3xs: 0.625rem;
    --padding-5xl: 1.5rem;
    --padding-16xl: 2.188rem;
    --padding-2xl: 1.313rem;
    --padding-14xl: 2.063rem;
    --padding-5xs: 0.5rem;
    --padding-base: 1rem;
    --padding-32xl-2: 3.2rem;
    --padding-23xl: 2.625rem;
    --padding-8xl: 1.688rem;
    --padding-61xl: 5rem;
    --padding-13xl: 2rem;
    --padding-18xl: 2.313rem;
    --padding-17xl: 2.25rem;
    --padding-95xl: 7.125rem;
    --padding-24xl: 2.688rem;
    --padding-mid: 1.063rem;
    --padding-4xs-1: 0.506rem;
    --padding-4xs-2: 0.513rem;
    --padding-9xs-9: 0.244rem;

    /* Border radiuses */
    --br-9xs: 4px;
    --br-3xs: 10px;
    --br-5xs: 8px;
    --br-base: 16px;
    --br-5xl: 24px;
    --br-6xs-7: 6.7px;

    --container-width-lg: 85%;
    --container-width-md: 90%;

    --transition: all 500ms ease;
}


/* Styling the scroll-bar */

/* Style the scrollbar track */
::-webkit-scrollbar {
    width: 6px;
}

/*(the draggable part) */
::-webkit-scrollbar-thumb {
    background: var(--black); /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
}

/* the scrollbar track when the mouse is over it */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-dark);
}

/* Style the scrollbar track */
/* Color of the scrollbar track */
::-webkit-scrollbar-track {
    background: var(--neutral-50); 
}


html {
    scroll-behavior: smooth;
    
}

body {
    font-family: var(--lato-font), sans-serif;
    background: var(--color-white);
    color: var(--black);
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.container {
    width: var(--container-width-md);
    max-width: 1920px;
    margin-inline: auto;
}

.container-payment {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}
.container-margin {
    margin-left: 72px   ;
}

.container-contact {
    width: 55%;
    max-width: 1920px;
    margin-inline: auto;
}

h1 {
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 4.8rem;
    color: #282828;
}

h3 {
    color: var(--color-white);
    font-family: 'DM Sans', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
}

small {
    color: var(--color-white);
    font-family: 'DM Sans', sans-serif;
    font-size: 0.63rem;
    font-weight: 500;
}

img {
    object-fit: cover;
    width: 100%;
}

a {
    color: var(--color-black);
}

h4 {
    color: #282828;
    font-weight: 700;
    font-size: var(--paragraph-01-bold-size);
    line-height: var(--padding-13xl);
}