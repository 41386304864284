.qa-section {
    margin-top: 6rem;
}

.qa-container {
    margin: 0 auto 0 auto;
    width: 60%;
}
.qa-container .qa__card{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
}

.qa__card .card {
    display: flex;
    gap: 1rem;
    padding: 2rem;
    align-items: center;
    border-radius: 10px;
    background: #f1efef;
}

.qa__card .card img {
    width: 35px;
    object-fit: cover;
}

.qa__card .card p {
    font-size: 24px;
    line-height: 2rem;
    font-weight: 700;
    color: #282828;
}



@media screen and (max-width: 1024px) {

    .qa-section {
        margin-top: 13rem;
    }

    .qa-container .qa__card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }


    .qa__card .card {
        padding: 1.7rem;
    }

    .qa__card .card p {
        font-size: 18px;
        line-height: 24px;
    }

    .qa__card .card img {
        width: 20px;
    }
}



/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .qa__card .card p {
        font-size: 22px;
    }

    .qa-container {
        width: 80%;
    }
}