.footer__section {
    background: #831111;
    border-top: 1px solid #FBFAFA;
}

.footer__section .footer__section-container {
    display: grid;
    grid-template-columns: 32rem 1fr 1fr 1fr;
    gap: 120px;
    padding-top: 84px;
    padding-bottom: 84px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
}

.footer__1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer__1 p {
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.01em;
}

.footer__1 img {
    width: 223px;
}

.footer__section-container h4 {
    /* display: inline; */
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
    white-space: nowrap;
}

.footer__section-container h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 2px; 
    width: 100%; 
    height: 3px; 
    background-image: linear-gradient(to right, #db1d1d, #db1d1d 50%, transparent 50%, transparent);
}

.footer__2,
.footer__3,
.footer__4 {
    display: flex;
    flex-direction: column;
    gap: 37px;
    white-space: nowrap;
}

.footer__4 {
    margin-left: -20px;
    width: fit-content;
}

.footer__section-container ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer__section-container a,
.footer__section-container li{
    color: #FBFAFA;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
}



.footer__section-container .services li {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
    white-space: nowrap;
}

.social-media {
    display: flex;
    flex-direction: row !important;
    gap: 8px !important;
}


.social-media img {
    /* width: 20px; */
    height: 20px !important;
    object-fit: cover;
}

/* .footer__section-container .social-media li */

/* Media Queries for smaller screens*/

@media screen and (max-width: 1024px) {
    .footer__section .footer__section-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .footer__1 {
        gap: 1rem;
    }

    .footer__1 p {
        font-size: 16px;
        line-height: 28px;
    }

    .footer__1 img {
        width: 174.5px;
    }

    .footer__section-container h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .footer__section-container h4::after {
        width: 25%; 
        bottom: -5px;
    }

    .footer__2,
    .footer__3,
    .footer__4 {
        gap: 16px;
    }

    /* footer last child alignment resolved for smaller screens */
    .footer__4 {
        margin-left: 0;
        width: fit-content;
    }

    .footer__section-container .footer__4 h4::after {
        width: 90%; 
        /* bottom: -5px; */
    }

    /* End of adjustment */
    .footer__section-container ul {
        gap: 8px;
    }

    .footer__section-container a,
    .footer__section-container li {
        font-size: 16px;
    }

    .footer__section-container .services li {
        font-size: 16px;
        line-height: 28px;
    }

    .social-media {
        display: flex;
        flex-direction: row !important;
        gap: 6px !important;
        align-items: center;
        /* justify-content: center; */
    }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .footer__section .footer__section-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 150px;
    padding-bottom: 50px;
    }

    .footer__section-container h4::after {
        width: 35%; 
        bottom: -5px;
    }

    .footer__section-container .footer__4 h4::after {
        width: 100%;
    }

    .footer__4 {
        margin-left: 0;
        width: fit-content;
    }
}