.steps__section {
    margin-top: 9.875rem;
}

.steps__section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
}

.steps__section-left {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.steps__section-left .title__block {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: fit-content;
}

.steps__section-left .title__block .small__header-text {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    color: #282828;
}

.steps__section-left .title__block h3 {
    font-weight: 900;
    font-size: 48px;
    line-height: 64px;

    color: #BF171D;

}

.steps__section-left .instruction__block {
    display: flex;
    flex-direction: column;
    gap: 2.625rem;
    justify-content: center;
}

.steps__section-left .instruction__block .instruction__tray .card {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 1.5rem;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 42px;
}

.steps__section-left .instruction__block .instruction__tray .card:nth-child(3)  {
    margin-bottom: 0;
}

.steps__section-left .instruction__block .instruction__tray .card .outer__circle {
    background: #fff;
    border-radius: 50%;
    width: 87px;
    height: 87px;
    position: relative;
    border: 0.966667px solid #DB1D1D;
    /* padding: 8px; */
}

.steps__section-left .instruction__block .instruction__tray .card .image__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 71px;
    height: 71px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #D9D9D9;
    padding: 20px;
    z-index: 2;
}

.steps__section-left .instruction__block .instruction__tray .card .outer__circle .image__circle img {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.steps__section-left .instruction__block .instruction__tray .card .text__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.steps__section-left .instruction__block .instruction__tray .card .text__body h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #831111;
}

.steps__section-left .instruction__block .instruction__tray .card .text__body p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #282828;
}

/* right hand section */

.steps__section-right {
    display: flex;
    align-items: center;
}



/* CTA LOGO */

.cta-s {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0.5rem;
}

.cta-s .cta-logo-s {
    display: flex;
    align-items: flex-end;
}

.cta-s a {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    text-align: left;
    background: black;
    border-radius: 0.5rem;
    border: 1px solid var(--White, #FBFAFA);
    padding: 0.25rem 1rem;
}

.cta-s span {
    display: flex;
    flex-direction: column;
    line-height: normal;
    gap: 0;
}


@media screen and (max-width: 1024px) {
    .steps__section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 45px;
    }

    .steps__section-left {
        order: 1;
    }

    .steps__section-left .title__block .small__header-text {
                
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

    .steps__section-left .title__block h3 {
    font-size: 32px;
    line-height: 48px;
    }
    

    .steps__section-left .instruction__block {
        gap: 1.5rem;
    }

    .steps__section-left .instruction__block .instruction__tray .card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: start;
    }

    .steps__section-left .instruction__block .instruction__tray .card .outer__circle {
        width: 52px;
        height: 52px;
    }

    .steps__section-left .instruction__block .instruction__tray .card .image__circle {
        width: 40px;
        height: 40px;
        padding: 10px;
    }

    .steps__section-left .instruction__block .instruction__tray .card .outer__circle .image__circle img {
        width: 19.21px;
        height: 19.21px;
        object-fit: cover;
    }

    .steps__section-left .instruction__block .instruction__tray .card .text__body h3 {
        font-weight: 800;
        font-size: 20px;
    }

    .steps__section-left .instruction__block .instruction__tray .card .text__body p {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
    }

    .cta-s .cta-logo-s {
        width: 19px;
    }

    .cta-s .btn-s {
        display: flex;
        align-items: center;
    }

    .cta-s a {
        padding: 3px 12px;
    }

    .cta-s span small {

        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 7.35254px;
        line-height: 10px;
    }
    .cta-s span h3 {

        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 11.7641px;
        line-height: 15px;
    }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    
}