.merchant__service-container {
    margin-top: 15.1rem;
    background: #831111;
}

.content__text-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0.5rem;
    padding-top: 99px;
    padding-bottom: 65px;
}

.content__text-container .text_content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
}

.content__text-container .text_content h5 {
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
    color: #FFFFFF;
}

.content__text-container .text_content p {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
}

.content__text-container .macBook_image {
    width: 599px;
    height: 487px;
    background: url(../images/mack-1.png) no-repeat;
    background-size: contain;
    background-position: right;
    margin-left: 0;
    margin-right: 0;
}

.mack__image-2 {
    display: none;
}

/* Media Queries Screen */

@media screen and (max-width: 1024px) {

    .content__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        width: 90% !important;
        margin-inline: auto !important;
        padding-top: 81px;
        padding-bottom: 81px;
        
    }

    .mack__image-2 {
        display: flex;
        justify-content: center;
    }

    .mack__image-2 img {
        width: 333.13px;
    }
    
    .content__text-container .macBook_image {
        display: none;
    }

    .content__text-container .text_content h5 {
        font-size: 32px;
        line-height: 48px;
    }

    .content__text-container .text_content p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }


}


/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .content__text-container .macBook_image {
        width: 600px;
        height: 350px;
        background: url(../images/mack-1.png) no-repeat;
        background-size: contain;
        background-position: right;
        margin-left: -150px;
        margin-right: -120px;
    }

    .content__text-container .text_content p {
        font-weight: 700;
        font-size: 22px;
    }

    .content__text-container .text_content h5 {
        font-size: 40px;
    }
}