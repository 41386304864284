.contact__section {
    margin-top: 158.22px;
    background: var(--secondary);
}

.contact__section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 82px 0 70px 0;
    gap: 42px;
}

.form__header .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
}

.form__header .card .form__header-head {

font-weight: 800;
font-size: 48px;
line-height: 64px;
color: #FBFAFA;
}

.form__header .card p {
font-weight: 700;
font-size: 20px;
line-height: 36px;
letter-spacing: 0.01em;

color: #FBFAFA;
}

.form__container form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.form__container form .form__row1,
.form__container form .form__row2 {
    display: flex;
    gap: 1rem;
}

.form__container form .form__row1 input,
.form__container form .form__row2 input,
.form__container form .form__row3 textarea {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 70px 10px 24px;
    width: 100%;
    gap: 10px;
    border: 1px solid #FBFAFA;
    border-radius: 10px;
    background: var(--secondary);
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #BDBDBD;
}

.form__container form .form__row3 textarea {
    height: 10rem;
}

.form__container form .form__row4 {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
    /* cursor: pointer; */
}

.form__container form .form__row4 img {
    width: 15.71px;
}

.form__container form .form__row4 button {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
    background: var(--primary);
    justify-content: center;
    padding: 16px 48px;
    background: #DB1D1D;
    border-radius: 4px;
    cursor: pointer;
    transition: all 500ms ease;
}

.form__container form .form__row4 button:hover {
    background: black;
}

@media screen and (max-width: 1024px) {
    .form__container form .form__row1,
.form__container form .form__row2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact__section-container {
    width: var(--container-width-md) !important;
}

.form__container form .form__row1 input,
.form__container form .form__row2 input,
.form__container form .form__row3 textarea {
    width: 100%;
}

.form__container {
    width: 100%;
}

.form__header .card .form__header-head {

    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    }

    .form__header .card p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    
}