.payment__service-section {
    margin-top: 9.875rem;
    background: #F0F3F6;
}

.payment__service-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    /* justify-content: center; */
    padding: 42px 0;
}

.header__heading h2 {
font-weight: 900;
font-size: 48px;
line-height: 64px;
text-align: center;
}

.header__heading .red__text {
    display: inline-flex;
    align-items: center;
    color: var(--primary);
}

.payment__service-image img {
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1024px) {
    .payment__service-section {
        margin-top: 8.5rem;
    }


    .payment__service-container {
        width: var(--container-width-md) !important;
        gap: 1rem;
        padding: 24px 0;
    }

    .header__heading h2 {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
    }

    .header__heading svg {
        width: 18px;
        height: 18px;
        margin-top: -5px;
    }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .header__heading h2 {
        font-size: 40px;
    }

    .header__heading svg {
        width: 20px;
        height: 20px;
        margin-top: -5px;
    }
}