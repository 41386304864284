/* ====================== FAQs ================================= */


.faq__section {
    margin-top: 158px;
    transition: all 500ms ease;
}

.faq__section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 85px;
}



/* LEFT SECTION */
.faq__left-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.faq__left-section h4 {
font-weight: 800;
font-size: 48px;
line-height: 64px;
}

.faq__left-section .small-faq-txt {
font-weight: 700;
font-size: 20px;
line-height: 36px;
}





/* RIGHT SECTION */
.faqs__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* align-items: flex-start; */
    /* justify-content: space-between; */
    
}

.faq {
align-items: center;
padding: 8px 16px;
gap: 100px;
background: #FBFAFA;
border-radius: 8px;
border: 0.5px solid transparent;
cursor: pointer;
transition: all 400ms ease;
}

.faq:hover {
    border: 0.5px solid var(--primary);
    box-shadow: 0 2rem 2rem  rgba(128, 23, 26, 0.7);
    z-index: 1;
    border-radius: 0;
    .faq__icon,
    .faq h4 {
        color:  rgba(128, 23, 26, 0.7);;
    }
}

.faq h4 {
font-weight: 600;
font-size: 24px;
line-height: 36px;
max-width: 80%;
/* letter-spacing: 0.01em; */
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 500ms ease;
}

.faq p {
    margin-top: 1.5rem;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;
}


@media screen and (max-width: 1024px) {
    .faq__section-container {
        display: flex;
        flex-direction: column;
        gap: 43px;
    }

    .faq__left-section h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    }

    .faq__left-section .small-faq-txt {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

    .faq h4 {
font-weight: 600;
font-size: 14px;
line-height: 24px;
max-width: 70%;
letter-spacing: 0.01em;
    }

.faq p {
    margin-top: 1.5rem;
    max-width: 90%;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
}

.faq__icon {
    font-size: 12px;
    color: black;
}

.faq__left-section .images__tray .card img{
    width: 20px;
}

.faq__left-section .active_text h5 {
font-weight: 700;
font-size: 16.2491px;
line-height: 24px;
}
}


/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .faq__left-section h4 {
        font-weight: 800;
        font-size: 40px;
        line-height: 60px;
        }
        
        .faq__left-section .small-faq-txt {
        font-weight: 700;
        font-size: 18px;
        line-height: 36px;
        }

        .faq h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            max-width: 80%;
            /* letter-spacing: 0.01em; */
            }

            .faq__section-container {
                gap: 75px;
            }
}