.container-mini {
    width: 80%;
    max-width: 1920px;
    margin-inline: auto;
}

.services-section {
    margin-top: 9.875rem;
}

.services-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.services-container .section__head {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.services-container .section__head h1 {
    /* Discover Top-tier Services */

font-weight: 900;
font-size: 48px;
line-height: 64px;
color: #282828;
}

.services-container .section__head h1 span {
    color: var(--primary);
}

.services-container .section__head p {
    /* We offer online financial services targeted at individuals and Businesses */
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    color: #282828;
}

.services-container .service__card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
}

.service__card .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.625rem 1.5rem;
    /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */

    

    

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    border-bottom: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
    border-left: 1px solid var(--primary);
    border-top: none;
    position: relative;
}


/*  COMING BACK TO THIS */
.service__card .empty {
    position: absolute;
    top: -8px;
    left: 0px;
    width: 100%;
    height: 50px;
    background-color: white;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1.9));
    z-index: 1;
    /* border-radius: 15px; */
   
    /* background: black; */
    pointer-events: none; /* Ensure the pseudo-element doesn't interfere with mouse events */
}

.service__card .card .image-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    padding: 22px;
    border-radius: 50%;
    background: lightblue;
    max-width: fit-content;
}
.service__card .card .image-icon img {
    width: 29.33px;
    object-fit: cover;
}

.service__card .card:nth-child(1) .image-icon,
.service__card .card:nth-child(5) .image-icon,
.service__card .card:nth-child(6) .image-icon {
    background: var(--color-info);
}


.service__card .card:nth-child(2) .image-icon {
    background: #9B51E0;
}

.service__card .card:nth-child(3) .image-icon {
    background: #27AE60;
}

.service__card .card:nth-child(4) .image-icon {
    background: #F08181;
}

.service__card .card h5 {
font-weight: 900;
font-size: 24px;
line-height: 32px;

color: #282828;
}

.service__card .card p {
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #282828;
}


@media screen and (max-width: 1024px) {
    .services-container .section__head {
        gap: 0.5rem;
    }

    .services-container .section__head h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
    }

    .services-container .section__head h1 span {
        display: block;
        justify-content: center;
        text-align: center;
    }

    .services-container .section__head p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
    }

    .services-container .service__card {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .service__card .card {
        padding: 24px 16px;
    }

    .service__card .card .image-icon {
        width: 48px;
        height: 48px;
    }

    .service__card .card .image-icon img {
        width: 19.56px;
    }

    .service__card .card h5 {
        font-size: 20px;
    }

    .service__card .card p {
        font-weight: 600;
        line-height: 28px;
        /* or 175% */
        letter-spacing: 0.01em;
    }


}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .container-mini {
        width: 80%;
    }

    .services-container {
        gap: 3rem;
    }

    .services-container .service__card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
        column-gap: 2rem;
    }
}