
/* MAIN HEADER STYLES */
.main__header {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin: -5rem 0 0 0;
}

.main__header-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.main__header-left h1 .custom-underline{
    color: var(--primary);
    position: relative;
    display: inline-block;
}

.main__header-left .custom-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px; /* Adjust the distance of the underline from the text */
    width: 100%;
    height: 25px; /* Adjust the thickness of the underline */
    background-image: url('../../images/Vector-curve.svg');
    object-fit: cover;
    background-repeat: no-repeat;
}


.cta {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0.5rem;
}

.cta .cta-logo{
    display: flex;
    align-items: flex-end;
}

.cta a {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    text-align: left;
    background: black;
    border-radius: 0.5rem;
    border: 1px solid var(--White, #FBFAFA);
    padding: 0.25rem 1rem;
}

.cta span {
    display: flex;
    flex-direction: column;
    line-height: normal;
    gap: 0;
}

/* ACTIVE USERS STYLES */

.active__users {
    display: flex;
    gap: 6px;
    align-items: center;
    max-width: fit-content;
}

.images__tray {
    display: flex;
    width: 100%;
    align-items: center;
}

.images__tray .card {
    margin-left: -10px;
}

.images__tray .card:first-child {
    margin-left: 0;
}

/* ACTIVE TEXT STYLES */

.active_text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.active_text h5 {
    font-size: 1.9rem;
    color: #282828;
    font-weight: 700;
    line-height: 3rem;
}

.txt {
    white-space: nowrap;
    font-weight: 400;
    margin-top: -0.4rem;
    font-size: 0.75rem;
    line-height: 16px;

color: #282828;
}


/* RIGHT SIDE OF MAIN HEADER */

.main__header-right {
    position: relative;
}

/* HERO TEXTS */

.hero__texts .hero__text {
    font-size: 16.6393px;
    line-height: 27px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #831111;
    padding: 8.31967px 18.3033px;
    border-radius: 6.65573px;
    background: rgba(251, 250, 250, 0.93);
    position: absolute;
    z-index: 5;
}

.hero__texts .hero__text:nth-child(1) {
    bottom: 42%;
    left: 1%;
}

.hero__texts .hero__text:nth-child(2) {
    bottom: 15%;
    left: 10%;
}

.hero__texts .hero__text:nth-child(3) {
    top: 28%;
    right:2%;
}
.circle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


.transparent__circle {
    position: relative;
    width: 420px;
    height: 450px;
    background: var(--White);
    margin: 20px 40px;
}

.transparent__circle::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 420px;
    background: transparent;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 0 100px 0 40px #fbfafa;
}

.transparent__circle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 420px;
    box-sizing: border-box;
    border-radius: 50%;
}

.transparent__circle .inner__circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 420px;
    background: #831111;
    overflow: hidden;
    border-radius: 50%;
}

.transparent__circle .inner__circle .lap img,
.transparent__circle img {
    position: absolute;
    bottom: -5%;
    left: 51%;
    transform: translateX(-50%);
    width: 275px;
}

.main__header-right .quadrant__image img {
    position: absolute;
    top: 3%;
    left: 76.8%;
    transform: translateX(-50%);
    width: 230px;
    /* height: 450px; */
    z-index: 1;
    clip-path: polygon(2% 0, 100% 0, 100% 100%, 20% 100%); /* Crop the left part */
}

.main__header-right .grouped__photo {
    display: none;
}


.log-in-signUp-h {
    display: none;
    align-items: center;
    /* justify-content: center; */
    gap: 1.5rem;
}

.btn-h {
    width: fit-content;
    padding: 0.5rem 1.5rem;
    line-height: 1.75rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid #bf161d;
}

.first-h {
    color: var(--primary);
    transition: var(--transition);
}

.second-h {
    color: var(--neutral-50);
    background: linear-gradient(102.96deg, #DB1D1D 37%, #750F0F 119.31%);
    transition: var(--transition);
}

a.first-h:hover {
    color: var(--neutral-50);
    background: var(--primary);
}

a.btn-h.second-h:hover {
    color: var(--primary);
    background: var(--white);
}


@media screen and (max-width: 1024px) {
    .main__header {
        margin-top: 3.5rem;
    }

    .main__header-container {
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        padding-bottom: 48px;
        gap: 1rem;
        /* justify-content: flex-start; */
    }

    .main__header-left {
        order: 1;
    }

    .hero__texts, .circle-container, .quadrant__image,
    .main__header-left .custom-underline::after {
        display: none;
    }

    .main__header-right .grouped__photo {
        display: flex;

    }

    .main__header-right .grouped__photo img {
        width: auto;
        object-fit: cover;
    }

    /* bottom section */

    .main__header-left h1 {
        font-weight: 900;
        font-size: 32px;
        line-height: 48px;
    }

    .main__header-left h1 br {
        display: none;
    }

    .main__header-left h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .cta {
        display: none;
    }

    .log-in-signUp-h {
        display: flex;
    }

    .active_text h5 {
    font-weight: 700;
    font-size: 27.3049px;
    line-height: 41px; 
    }
    
    .txt {
        font-size: 8.53278px;
        line-height: 14px;
    }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .main__header {
        margin-top: 5rem;
    }

    .main__header-container {
        grid-template-columns: 1.5fr 1fr;
        padding-top: 1rem;
        gap: 0;
    }

    .main__header-left h1 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
        min-width: 535px;
    }

    .main__header-right {
        margin-left: -2rem;
    }

    .main__header-left .custom-underline::after {
        bottom: -10px;
        height: 20px;
    }
}