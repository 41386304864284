.testimonial-section {
    margin-top: 9.875rem;
}



.testimonial__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    background: #831111;
    border-radius: 16px;
    width: 100%;
    height: 21rem;
}

.testimonial__header  .head-txt {

    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #FBFAFA;
}

.testimonial__header h3 {
    font-weight: 900;
    font-size: 48px;
    line-height: 64px;
}

.testimonial__card-pack {
    display: flex;
    gap: 22px;
    z-index: 2;
    margin-top: -2.5rem;
}

.testimonial__card-pack .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.625rem 1.5rem;
    border-radius: 1rem;
    background: var(--white);
    max-width: 381px;
    min-height: 314px;
    box-shadow:  0px 2px 8px rgba(0, 0, 0, 0.25);
    transition: all 400ms ease-in-out;
}

.testimonial__card-pack .card:hover {
    background: var(--color-white);
    border: #db1d1d;
    color: #831111;
    box-shadow: 0 2rem 2rem  rgba(219, 38, 44, 0.7);
    z-index: 1;
    border-radius: 0;
}

.testimonial__card-pack .card .customer_profile {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.testimonial__card-pack .card .customer_profile .customer__photo img {
    border-radius: 50%;
}

.testimonial__card-pack .card .customer_profile .customer__name-rate {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.testimonial__card-pack .card .customer_profile .customer__name-rate h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;

}

.testimonial__card-pack .card .customer_profile .customer__name-rate .star-rate {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #FABD00;
}

.testimonial__card-pack .card .customer_profile .customer__name-rate .star-rate .stars {
    display: flex;
    align-items: center;
}

.testimonial__card-pack .card .customer_profile .customer__name-rate .star-rate .stars svg {
    /* fill: #FABD00; */
}

.customer__words {
font-weight: 600;
font-size: 1rem;
line-height: 24px;
letter-spacing: 0.01em;
}


/* ================================== MEDIA QUERIES ========================== */

@media screen and (max-width: 1024px) {
    .testimonial__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 34px;
    }

    .testimonial__header {
        text-align: center;
        border-radius: 0;
    }

    .testimonial__header  .head-txt {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .testimonial__header h3 {
        font-size: 32px;
    }

    .testimonial__card-pack {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        z-index: 2;
        margin-top: -6rem;
    }
}

/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .testimonial__card-pack {
        display: grid;
        grid-template-columns: repeat(3, auto);
        place-items: center;
        justify-content: center;
    }

    .testimonial__card-pack .card {
        
    }
}