nav {
    /* height: 7.5rem; */
    width: 100vw;
    display: grid;
    place-items: center;
    position: fixed;
    box-shadow: 0px 2px 4px 0px #D9D9D9;
    top: 0;
    left: 0;
    z-index: 99;
}

nav, header {
    background: var(--white);
}

/* only shows on medium and small screens */
.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    padding: 1.8rem 0 1.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 12.5rem;
}

.logo-m {
    display: none;
}

.nav__links {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    transition: all 600ms ease;
}

.nav__links a {
    line-height: 1.7rem;
    font-weight: 700;
    font-size: 1.25rem;
    transition: var(--transition);
    color: var(--color-black);
    transition: all 400ms ease !important;
}

.nav__links a:hover {
    text-decoration: underline;
}

.log-in-signUp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.btn {
    width: fit-content;
    padding: 0.9rem 2.5rem;
    line-height: 1.75rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid #bf161d;
    /* transition: var(--transition); */
}

.first{
    color: var(--primary);
    transition: var(--transition);
}

.second {
    color: var(--neutral-50);
    background: linear-gradient(102.96deg, #DB1D1D 37%, #750F0F 119.31%);
    transition: var(--transition);
}

a.first:hover {
    color: var(--neutral-50);
    background: var(--primary);
}

a.btn.second:hover {
    color: var(--primary);
    background: var(--white);
}





/* Media Queries for medium and small devices */

@media screen and (max-width: 1024px) {

    nav {
        height: 3.5rem;
    }


    .nav__container {
        padding: 0.5rem 1rem;
    }

    .nav__toggle-btn {
        color: #16161D;
        display: inline-block;
        background: transparent;
        font-size: 1.2rem;
        cursor: pointer;
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 200px;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        background: var(--white);
        animation: navAnimation 400ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }
    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }
    .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }
    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }
    .nav__links li:nth-child(6) {
        animation-delay: 1s;
    }
    .nav__links li:nth-child(7) {
        animation-delay: 1.2ms;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
        
    }

    .nav__links li a {
        height: 100%;
        width: 100%;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }

    .log-in-signUp {
        display: none;
    }

    .logo {
        display: none;
    }

    .logo-m {
        display: flex;
        align-items: center;
        width: 10.25rem;
    }
}


/* ======================================= MEDIA QUERIES FOR SMALL DESKTOPS ======================================== */

@media (min-width: 1025px) and (max-width: 1320px) {
    .nav__container {
        padding: 1rem 0 1rem 0;
    }

    .logo {
        width: 9rem;
    }

    .nav__links {
        gap: 1.2rem;
    }

    .nav__links a {
        line-height: 1.2rem;
        font-weight: 600;
        
    }

    .btn {
        padding: 0.5rem 1.5rem;
        font-weight: 500;
    }
}